<template>
  <v-card>
    <div class="pa-3">
      <v-text-field
        label="Nombre"
        filled
        hide-details
        v-model="nuevoTipo.tipo"
        :disabled="nuevoTipo.locked"
        :append-icon="nuevoTipo.locked ? 'mdi-lock' : ''"
      >
      </v-text-field>
      <br />
      <v-autocomplete
        v-model="nuevoTipo.icon"
        :items="icons"
        filled
        hide-details
        prepend-inner-icon="mdi-magnify"
        clearable
      >
        <template v-slot:selection="{ item }">
          <v-icon left>{{ item }}</v-icon>
          {{ item }}
        </template>
        <template v-slot:item="{ item }">
          <v-icon left>{{ item }}</v-icon>
          {{ item }}
        </template>
      </v-autocomplete>
      <br />
      <div class="d-flex justify-center pa-2">
        <v-color-picker
          :elevation="2"
          show-swatches
          width="400"
          v-model="color"
          mode="hexa"
        >
        </v-color-picker>
      </div>
      <br />
      <v-textarea
        label="Descripción"
        v-model="nuevoTipo.descripcion"
        filled
        hide-details
        rows="1"
        auto-grow
      >
      </v-textarea>

      <v-subheader>Preview</v-subheader>
      <v-divider class="mb-2"></v-divider>
      <v-sheet
        v-for="n in 2"
        :key="n"
        :dark="Boolean(n - 1)"
        :light="Boolean(!(n - 1))"
        style="gap: 10px"
        class="d-flex flex-wrap align-center mb-2 pa-2"
        :elevation="4"
      >
        <v-chip label outlined class="v-chip--active mr-1" :color="newColor">
          <v-icon left>{{ nuevoTipo.icon }}</v-icon>
          {{ nuevoTipo.tipo }}
        </v-chip>
        <v-alert
          max-width="500"
          style="width: 100%"
          class="ma-0"
          text
          prominent
          dense
          :color="newColor"
          :icon="nuevoTipo.icon"
        >
          <div class="text-h6">
            {{ nuevoTipo.tipo }}
          </div>
          <v-sheet
            width="100%"
            height="2px"
            class="mb-2"
            :color="newColor"
          ></v-sheet>
          <div>{{ nuevoTipo.descripcion }}</div>
        </v-alert>
      </v-sheet>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="tipo.idTipo"
        @click.stop="eliminarTipo"
        color="error darken-2"
        :disabled="nuevoTipo.locked"
      >
        <v-icon v-if="nuevoTipo.locked" left>mdi-lock</v-icon>
        <v-icon v-else left>mdi-delete</v-icon>
        Eliminar
      </v-btn>
      <v-btn @click.stop="guardarTipo" color="primary">
        <v-icon left>mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import * as icons from "@mdi/js";
import { getSituacionIcon } from "@/utils/index.js";
import colors from "vuetify/lib/util/colors";

export default {
  props: {
    tipo: Object,
  },
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      nuevoTipo: {
        ...this.tipo,
        color: this.tipo.color ? this.hexColor(this.tipo.color) : "",
      },
      icons: Object.keys(icons).map((string) =>
        string
          .replace(/([a-z])([A-Z])/g, "$1-$2")
          .replace(/\s+/g, "-")
          .toLowerCase()
      ),
    };
  },
  methods: {
    getSituacionIcon,
    hexColor(name) {
      let auxColors = { ...this.$vuetify.theme.currentTheme };
      Object.keys(auxColors).map(
        (key, index) => (auxColors[key] = { base: auxColors[key] })
      );
      const allColors = { ...colors, ...auxColors };
      console.log(allColors);
      if (name[0] == "#") return name;
      const [nameFamily, nameModifier] = name.split(" ");
      const shades = ["black", "white", "transparent"];
      const util = { family: null, modifier: null };
      if (shades.find((shade) => shade === nameFamily)) {
        util.family = "shades";
        util.modifier = nameFamily;
      } else {
        const [firstWord, secondWord] = nameFamily.split("-");
        util.family = `${firstWord}${
          secondWord
            ? secondWord.charAt(0).toUpperCase() + secondWord.slice(1)
            : ""
        }`;
        util.modifier = nameModifier ? nameModifier.replace("-", "") : "base";
      }
      return allColors[util.family][util.modifier];
    },
    guardarTipo() {
      const nuevo = { ...this.nuevoTipo, color: this.newColor };
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/tiposContactos/crear.php`,
        data: {
          tipo: nuevo,
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha guardado el tipo correctamente");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido guardar el tipo");
        });
      // console.log(nuevo)
    },
    async eliminarTipo() {
      if (
        !(await this.$refs.confirm.open(
          "Eliminar tipo",
          "¿Estás seguro de que quieres eliminar este tipo? Algunos estudios prodrían verse afectados",
          { confirmColor: "error", width: 600 }
        ))
      )
        return;
      const nuevo = { ...this.nuevoTipo, color: this.newColor };
      axios({
        method: "DELETE",
        url: `${process.env.VUE_APP_API_URL}/tiposContactos/crear.php`,
        data: {
          tipo: nuevo,
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha eliminado el tipo correctamente");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido eliminar el tipo");
        });
      // console.log(nuevo)
    },
  },
  computed: {
    color: {
      get() {
        return this.nuevoTipo.color;
      },
      set(v) {
        this.nuevoTipo.color = v;
      },
    },
    newColor() {
      if (this.color == null) return "";
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
  },
};
</script>

<style>
.active-tipo {
  border-color: var(--v-primary-base) !important;
  color: var(--v-primary-base) !important;
}
.tipo-icon {
  position: absolute !important;
  top: 5px;
  left: 5px;
}
</style>
